.admin-exam-questions {
    border: .8px solid #979797;
    border-radius: 7px;
    margin-top: 10px;
    width: 50vw;
    text-align: left
}
.admin-exam-question {
    font-size: 15px;
     display: inline; 
     color: black;
}
.inline {
    display: inline;
}
.nav-btn-pagination {
    cursor: pointer;
}

#category {
    width: 150px;
}
#exam-type {
    width: 150px;
}
#sort {
    width: 150px;
}
